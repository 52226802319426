.preloader-default-theme {
    text-align: center;

    i {
        font-size: 26px;
        color: color('primary');
    }
}

#project,
#property,
#property-group-view,
#property-show-map-view,
#project-group-view,
#generated-links-group-view {
    .preloader {
        text-align: center;

        i {
            font-size: 26px;
            color: color('primary');
        }
    }
}

#property-group-view,
#project-group-view {
    .preloader {
        text-align: center;

        i {
            font-size: 26px;
            color: color('primary');
        }
    }

    .section {
        padding-top: 20px;
        padding-bottom: 40px;
    }
}

#load-wrapper {
    transition: all 2s ease;
    overflow: hidden;
}

#load-wrapper-homepage {
    transition: all 2s ease;
    overflow: hidden;
}

.group-view-options {
    display: table;

    @media (max-width: 680px) {
        width: 100%;
    }

    li {
        font-size: 14px;
        font-weight: 300;
        line-height: 1;
        border: 1px solid color('group-view-li', '', '', $colors-group-view);
        margin: 0 !important;
        display: table-cell;
        cursor: pointer;

        button {
            padding: 11px 10px;
            // color: color('secondary');
            font-weight: 300;

            &:hover {
                color: #fff;
            }
        }

        &.active {
            border-color: color('group-view-li-active', '', '', $colors-group-view);

            a,
            i {
                color: color('group-view-li-active', '', '', $colors-group-view);
            }
        }

        &:hover {
            border-color: color('group-view-li-hover', '', '', $colors-group-view);
            background: color('group-view-li-hover', '', '', $colors-group-view);

            a,
            i {
                color: #FFF;
            }
        }

        a {
            display: inline-block;
            padding: 11px 15px;

            &:hover {
                text-decoration: none;
            }
        }

        i {
            color: color('group-view-li', '', '', $colors-group-view);
            font-size: 16px;
            margin-left: 5px;
        }
    }
}

.group-view-options li.save-search {
    display: none;
}